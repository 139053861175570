<template>
    <div class="min-h-screen flex flex-row w-full pt-6 sm:pt-0">
        <div class="flex-1 w-full flex flex-col sm:justify-center items-center px-8 py-6">
            <div class="lg:w-[24rem] w-full">
                <slot />
            </div>
        </div>

        <div class="flex-1 w-full hidden sm:flex justify-center flex-col items-center px-8">
            <slot name="right" />
        </div>
    </div>
</template>
<script setup>
</script>
